<template>
<div>
    <el-tabs class="done" type="border-card" v-model="activeName" @tab-click="tab1Click">
        <el-tab-pane label="组织" name="organization">
            <Organization ref="organization" :config="customerConfig" @onSelectedRow="onOrganizationSelectedRow">
            </Organization>
        </el-tab-pane>
        <el-tab-pane label="客商" name="customervendor">
            <CustomerVendor ref="customervendor" :config="customerConfig" @onSelectedRow="onCustomerVendorSelectedRow">
            </CustomerVendor>
        </el-tab-pane>
        <el-tab-pane label="货主" name="customer">
            <Customer ref="customer" :config="customerConfig" @onSelectedRow="onCustomerSelectedRow">
            </Customer>
        </el-tab-pane>
        <el-tab-pane label="承运商" name="carrier">
            <Carrier ref="carrier" :config="customerConfig" @onSelectedRow="onCarrierSelectedRow"></Carrier>
        </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
export default {
    data() {
        return {
            activeName: "organization",
            customerConfig: {
                isDetailDisplay: true,
                isSelectionDisplay: true,
            },
        }
    },
    props: {
        config: {
            isDetailDisplay: false
        },
        currentSelectedRows: {
            customerRows: [],
            organizationRows: [],
            carrierRows: [],
            customervendorRows: [],
        },
    },
    mounted() {

    },
    watch: {

    },
    created: function () {

    },
    methods: {
        tab1Click: function (tab, event) {
            switch (tab) {
                case "organization":
                    break;
                case "customervendor":
                    break;
                case "customer":
                    break;
                case "carrier":
                    break;
            }
        },
        onCustomerSelectedRow: function (data) {
            this.currentSelectedRows.customerRows = data;
        },
        onOrganizationSelectedRow(data) {
            this.currentSelectedRows.organizationRows = data;
        },
        onCarrierSelectedRow(data) {
            this.currentSelectedRows.carrierRows = data;
        },
        onCustomerVendorSelectedRow(data) {
            this.currentSelectedRows.customervendorRows = data;
        }
    },
    components: {
        "Customer": resolve => {
            require(['@/components/business/selector/customerselector.vue'], resolve)
        },
        "Organization": resolve => {
            require(['@/components/business/selector/organizationselector.vue'], resolve)
        },
        "CustomerVendor": resolve => {
            require(['@/components/business/selector/customervendorselector.vue'], resolve)
        },
        "Carrier": resolve => {
            require(['@/components/business/selector/carrierselector.vue'], resolve)
        },
    }
}
</script>
